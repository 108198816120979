<template>
  <div class="welcome">
    <img src="../assets/hy.png" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.welcome{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
   img{
   object-fit: contain;
   margin: auto;
   }
}
</style>